import React from 'react'
import './investorMainBanner.css'
import InvestorImage from "../../images/InvestorMainBanner.png"

export default function InvestorMainBanner() {
  return (
      <div className='investorMain' style={{ position: "relative" }}>
          <img className="investorMain-home-image" alt="" src={InvestorImage} />
          <div className="investorMain-image-text-box">
            <div className="investorMain-image-background-text">
              <h1 className="investorMain-image-topic">
                Investor Desk
              </h1>
            </div>
          </div>
      </div>
  )
}
