import React from 'react'
import './investorWhyInvest.css'
import InvestorWhyImage1 from "../../images/rectangle-150@2x.png"
import InvestorWhyImage2 from "../../images/vector-27.svg"
import InvestorWhyImage3 from "../../images/rectangle-1501@2x.png"
import InvestorWhyImage4 from "../../images/rectangle-1502@2x.png"
import InvestorWhyImage5 from "../../images/rectangle-147@2x.png"
import InvestorWhyImage6 from "../../images/vector-06.svg"
import InvestorWhyImage7 from "../../images/vector-61.svg"
import InvestorWhyImage8 from "../../images/group-7.svg"
import InvestorWhyImage9 from "../../images/mask-group1@2x.png"
import InvestorWhyImage10 from "../../images/group-8.svg"
import InvestorWhyImage11 from "../../images/mask-groupp@2x.png"

export default function InvestorWhyInvest() {
  return (
    <div>
      <div className="whyInvest-investor-desk">
        <div className="whyInvest-investor-desk1">
          <div className="whyInvest-investor-desk2">
            <div className="whyInvest-investor-desk3">
                  <div className="whyInvest-investor-desk-child" />
                  <div className="whyInvest-investor-desk-item" />
                  <div className="whyInvest-investor-desk-inner" />
                  <div className="whyInvest-rectangle-div" />
                  <div className="whyInvest-business-home-leroux-1-parent">
                    <div className="whyInvest-group-parent">
                      <div className="whyInvest-vector-parent">
                        <img className="whyInvest-group-child" alt="" />
                        <img
                          className="whyInvest-group-item"
                          alt=""
                          src={InvestorWhyImage1}
                        />
                        <div className="whyInvest-reports-presentations">{`Reports & Presentations`}</div>
                      </div>
                      <div className="whyInvest-vector-group">
                        <img className="whyInvest-group-inner" alt="" src={InvestorWhyImage2} />
                        <img
                          className="whyInvest-group-item"
                          alt=""
                          src={InvestorWhyImage3}
                        />
                        <div className="whyInvest-reports-presentations">{`News & Announcement​`}</div>
                      </div>
                      <div className="whyInvest-vector-container">
                        <img className="whyInvest-group-inner" alt="" src={InvestorWhyImage2} />
                        <img
                          className="whyInvest-group-item"
                          alt=""
                          src={InvestorWhyImage4}
                        />
                        <div className="whyInvest-reports-presentations">Sustainability</div>
                      </div>
                    </div>
                    <div className="whyInvest-rectangle-parent">
                      <div className="whyInvest-group-child2" />
                      <img className="whyInvest-group-icon" alt="" />
                      <div className="whyInvest-group-container">
                        <div className="whyInvest-profit-in-q4-202324-parent">
                          <div className="whyInvest-profit-in-q4-container">
                            <p className="whyInvest-p">Profit in Q4</p>
                            <p className="whyInvest-p">2023/24</p>
                          </div>
                          <div className="whyInvest-rs-135-bn">Rs. 1.35 Bn</div>
                        </div>
                        <img className="whyInvest-mask-group-icon" alt="" />
                      </div>
                      <div className="whyInvest-heading1">{`Key Highlights & Indicators`}</div>
                    </div>
                    <div className="whyInvest-rectangle-group">
                      <img
                        className="whyInvest-group-child3"
                        alt=""
                        src={InvestorWhyImage5}
                      />
                      <img className="whyInvest-group-child4" alt="" />
                      <div className="whyInvest-button3">
                        <div className="whyInvest-button-child" />
                        <div className="whyInvest-read-more">Read More</div>
                        <img className="whyInvest-button-item" alt="" />
                      </div>
                      <div className="whyInvest-button-parent">
                        <div className="whyInvest-button4">
                          <div className="whyInvest-button-child" />
                          <div className="whyInvest-read-more">Read More</div>
                          <img className="whyInvest-button-item" alt="" />
                        </div>
                        <div className="whyInvest-group-div">
                          <div className="whyInvest-total-value-rs-million-parent">
                            <div className="whyInvest-total-value-rs">
                              Total Value Rs. Million
                            </div>
                            <div className="whyInvest-div">947.70</div>
                          </div>
                          <div className="whyInvest-per-share-rs-parent">
                            <div className="whyInvest-per-share-rs">Per Share Rs.</div>
                            <div className="whyInvest-div">2.50</div>
                          </div>
                        </div>
                      </div>
                      <div className="whyInvest-heading2">Dividend Information</div>
                    </div>
                    <div className="whyInvest-rectangle-container">
                      <div className="whyInvest-group-child5" />
                      <div className="whyInvest-group-child6" />
                      <img className="whyInvest-group-child4" alt="" />
                      <div className="whyInvest-button5">
                        <div className="whyInvest-button-child" />
                        <div className="whyInvest-read-more">Read More</div>
                        <img className="whyInvest-button-item" alt="" />
                      </div>
                      <div className="whyInvest-button-group">
                        <div className="whyInvest-button6">
                          <div className="whyInvest-button-child" />
                          <div className="whyInvest-read-more">Read More</div>
                          <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                        </div>
                        <div className="whyInvest-button7">
                          <div className="whyInvest-button-child6" />
                          <div className="whyInvest-cicx0000-parent">
                            <div className="whyInvest-cicx0000">CIC.X0000</div>
                            <img
                              className="whyInvest-group-child8"
                              alt=""
                              src={InvestorWhyImage7}
                            />
                          </div>
                        </div>
                        <div className="whyInvest-button8">
                          <div className="whyInvest-button-child6" />
                          <div className="whyInvest-cicn0000-parent">
                            <div className="whyInvest-cicn0000">CIC.N0000</div>
                            <img
                              className="whyInvest-group-child9"
                              alt=""
                              src={InvestorWhyImage7}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="whyInvest-heading3">Share Information</div>
                    </div>
                    <div className="whyInvest-rectangle-parent1">
                      <div className="whyInvest-group-child10" />
                      <img className="whyInvest-group-child4" alt="" />
                      <div className="whyInvest-button3">
                        <div className="whyInvest-button-child" />
                        <div className="whyInvest-read-more">Read More</div>
                        <img className="whyInvest-button-item" alt="" />
                      </div>
                      <div className="whyInvest-button-container">
                        <div className="whyInvest-button4">
                          <div className="whyInvest-button-child" />
                          <div className="whyInvest-read-more">Read More</div>
                          <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                        </div>
                        <div className="whyInvest-group-div">
                          <div className="whyInvest-total-value-rs-million-parent">
                            <div className="whyInvest-total-value-rs">
                              Total Value Rs. Million
                            </div>
                            <div className="whyInvest-div">947.70</div>
                          </div>
                          <div className="whyInvest-per-share-rs-parent">
                            <div className="whyInvest-per-share-rs">Per Share Rs.</div>
                            <div className="whyInvest-div">2.50</div>
                          </div>
                        </div>
                      </div>
                      <div className="whyInvest-heading4">Dividend Information</div>
                    </div>
                    <div className="whyInvest-rectangle-parent2">
                      <div className="whyInvest-group-child12" />
                      <img className="whyInvest-group-child4" alt="" />
                      <div className="whyInvest-button3">
                        <div className="whyInvest-button-child" />
                        <div className="whyInvest-read-more">Read More</div>
                        <img className="whyInvest-button-item" alt="" />
                      </div>
                      <div className="whyInvest-button-container">
                        <div className="whyInvest-button4">
                          <div className="whyInvest-button-child" />
                          <div className="whyInvest-read-more">Read More</div>
                          <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                        </div>
                        <div className="whyInvest-group-div">
                          <div className="whyInvest-total-value-rs-million-parent">
                            <div className="whyInvest-total-value-rs">Non-Voting Shares</div>
                            <div className="whyInvest-div">5.0 Bn</div>
                          </div>
                          <div className="whyInvest-per-share-rs-parent">
                            <div className="whyInvest-per-share-rs">Voting Shares</div>
                            <div className="whyInvest-div">22.3 Bn</div>
                          </div>
                        </div>
                      </div>
                      <div className="whyInvest-heading4">Market Capitalization</div>
                    </div>
                    <div className="whyInvest-rectangle-parent3">
                      <div className="whyInvest-group-child14" />
                      <img className="whyInvest-group-child15" alt="" src={InvestorWhyImage8} />
                      <div className="whyInvest-button13">
                        <div className="whyInvest-button-child" />
                        <div className="whyInvest-read-more">Read More</div>
                        <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                      </div>
                      <div className="whyInvest-group-parent3">
                        <div className="whyInvest-profit-in-q4-202324-parent">
                          <div className="whyInvest-profit-in-q4-container">
                            <p className="whyInvest-p">Profit in Q4</p>
                            <p className="whyInvest-p">2023/24</p>
                          </div>
                          <div className="whyInvest-rs-135-bn">Rs. 1.35 Bn</div>
                        </div>
                        <img
                          className="whyInvest-mask-group-icon"
                          alt=""
                          src={InvestorWhyImage11}
                        />
                      </div>
                      <div className="whyInvest-heading4">{`Key Highlights & Indicators`}</div>
                    </div>
                    <div className="whyInvest-rectangle-parent4">
                      <div className="whyInvest-group-child16" />
                      <img className="whyInvest-group-child15" alt="" src={InvestorWhyImage8} />
                      <div className="whyInvest-button13">
                        <div className="whyInvest-button-child" />
                        <div className="whyInvest-read-more">Read More</div>
                        <img className="whyInvest-button-item" alt="" src={InvestorWhyImage6} />
                      </div>
                      <div className="whyInvest-group-parent3">
                        <div className="whyInvest-profit-in-q4-202324-parent">
                          <div className="whyInvest-profit-in-q4-container">
                            <p className="whyInvest-p">Turnover in Q4</p>
                            <p className="whyInvest-p">2023/24</p>
                          </div>
                          <div className="whyInvest-rs-135-bn">Rs. 16.27 Bn</div>
                        </div>
                        <img
                          className="whyInvest-mask-group-icon"
                          alt=""
                          src={InvestorWhyImage9}
                        />
                      </div>
                      <div className="whyInvest-heading4">{`Key Highlights & Indicators`}</div>
                    </div>
                    <div className="whyInvest-heading8">Investor Statistics</div>
                  </div>
                  <div className="whyInvest-button-parent2">
                    <div className="whyInvest-button15">
                      <div className="whyInvest-button-child20" />
                      <div className="whyInvest-read-more10">Read More</div>
                      <img className="whyInvest-button-child21" alt="" />
                    </div>
                    <div className="whyInvest-button16">
                      <div className="whyInvest-button-child22" />
                      <div className="whyInvest-read-more">Read More</div>
                      <img className="whyInvest-button-item" alt="" src={InvestorWhyImage7} />
                    </div>
                    <div className="whyInvest-cic-holdings-plc">
                      CIC Holdings PLC provides a unique investment opportunity in the
                      Sri Lankan Agri Produce, Health and Personal Care, Livestock
                      Solutions, Industrial Solutions and Crop Solutions markets. Our
                      strength can be attributed directly to its strict adherence to a
                      unitary vision to improve society by nurturing the lives of
                      those we touch. We at CIC believe in sustainable and ethical
                      business practices and adherence to the highest international
                      standards, so that we provide the best possible outcome for our
                      customers, our partners, as well as our investors.
                    </div>
                    <div className="whyInvest-why-invest-in">Why Invest in CIC?</div>
                    <img className="whyInvest-group-child18" alt="" src={InvestorWhyImage10} />
                  </div>
            </div>
            <div className="whyInvest-contact-20">
            <div className="whyInvest-section-title">
              <div className="whyInvest-content3">
                <div className="whyInvest-heading">Contact Information</div>
                <div className="whyInvest-text1">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. `}</div>
              </div>
            </div>
            <div className="whyInvest-row1">
              <div className="whyInvest-content23">
                <img className="whyInvest-icon-relume" alt="" />
                <div className="whyInvest-contact-info">
                  <div className="whyInvest-content24">
                    <div className="whyInvest-heading10">Viraj Manathunga</div>
                    <div className="whyInvest-text21">Group Chief Strategy Officer</div>
                  </div>
                  <div className="whyInvest-link-parent">
                    <div className="whyInvest-link">viraj@cic.lk</div>
                    <div className="whyInvest-link1">+94 77 725 2906</div>
                  </div>
                </div>
              </div>
              <div className="whyInvest-content23">
                <img className="whyInvest-icon-relume" alt="" />
                <div className="whyInvest-contact-info1">
                  <div className="whyInvest-content24">
                    <div className="whyInvest-heading10">Damodara Wickramanayake</div>
                    <div className="whyInvest-text21">CFO CIC Capital/ Chemanex PLC</div>
                  </div>
                  <div className="whyInvest-link-parent">
                    <div className="whyInvest-link">damodara@cic.lk</div>
                    <div className="whyInvest-link1">+94 77 137 9947</div>
                  </div>
                </div>
              </div>
              <div className="whyInvest-content23">
                <img className="whyInvest-icon-relume" alt="" />
                <div className="whyInvest-contact-info">
                  <div className="whyInvest-content24">
                    <div className="whyInvest-heading10">Uvini Dias</div>
                    <div className="whyInvest-text21">Business Analyst</div>
                  </div>
                  <div className="whyInvest-link-parent">
                    <div className="whyInvest-link">uvini@cic.lk</div>
                    <div className="whyInvest-link1">+94 77 039 4913</div>
                  </div>
                </div>
              </div>
              <div className="whyInvest-content29">
                <img className="whyInvest-icon-phone" alt="" />
                <div className="whyInvest-contact-info3">
                  <div className="whyInvest-content30">
                    <b className="whyInvest-heading24">Phone</b>
                    <div className="whyInvest-text4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in ero.
                    </div>
                  </div>
                  <div className="whyInvest-link6">+1 (555) 000-0000</div>
                </div>
              </div>
              <div className="whyInvest-content31">
                <img className="whyInvest-icon-phone" alt="" />
                <div className="whyInvest-contact-info3">
                  <div className="whyInvest-content30">
                    <b className="whyInvest-heading24">Office</b>
                    <div className="whyInvest-text4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in ero.
                    </div>
                  </div>
                  <div className="whyInvest-link6">123 Sample St, Sydney NSW 2000 AU</div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
