import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import InvestorMainBanner from "../templates/InvestorDesktop/investorMainBanner"
import InvestorWhyInvest from "../templates/InvestorDesktop/investorWhyInvest"


export default function MediaRoom() {
  return (
    <div>
      <Header />
      <InvestorMainBanner />
      <InvestorWhyInvest />
      <Footer />
    </div>
  )
}
